<template>
  <v-container>
    <!-- Banner header -->
    <Banner :title="'Historial de reembolsos'" />

    <!-- Data table withdraws -->
    <v-card class="my-8">
      <DataTable
        :headers="tableHeaders"
        :items="withdraws"
        :totalItems="$store.state.investors.totalCountWithdraws"
        @clickNextIcon="handleNextIcon"
        @clickPrevIcon="handlePrevIcon"
        @inputSearch="handleSearch"
        @changeItemsPerPage="getWithdraws"
      >
        <template v-slot:[`item.investor`]="{ item }">
          <a @click="pushToInvestorSheet(item.investor.id)">
            <u>{{ item.investor.name }}</u>
          </a>
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          {{ currency(item.amount, { fromCents: true }) }}
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ formatDateLWithTime(item.created_at) }}
        </template>
        <template v-slot:[`item.completed_at`]="{ item }">
          {{ formatDateLWithTime(item.completed_at) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn v-if="item.status === 'PENDING'" x-small @click="(withdrawSelected = item), (dialogConfirmSentWithdraw = true)">
            Pasar a enviado
          </v-btn>
          <v-btn
            v-if="user.role === 'SUPERADMIN' && item.status === 'PENDING'"
            small
            icon
            class="ml-2"
            @click="(withdrawSelected = item), (dialogCancelWithdraw = true)"
          >
            <v-icon color="red">cancel</v-icon>
          </v-btn>
        </template>
      </DataTable>
    </v-card>

    <!-- Dialog confirm sent withdraw -->
    <DialogBox :model="dialogConfirmSentWithdraw" :color="'warning'">
      <template slot="toolbar">
        <span>¿Estás seguro de pasar a enviado el reembolso?</span>
      </template>
      <template slot="actions">
        <v-btn text @click="dialogConfirmSentWithdraw = false">Cancelar</v-btn>
        <v-btn text @click="completeWithdraw()">Pasar a enviado</v-btn>
      </template>
    </DialogBox>

    <!-- Dialog delete withdraw -->
    <DialogBox :model="dialogCancelWithdraw" :color="'error'" isdark>
      <template slot="toolbar">
        <span>¿Estás seguro de eliminar este reembolso?</span>
      </template>
      <template slot="actions">
        <v-btn text @click="dialogCancelWithdraw = false">Cerrar</v-btn>
        <v-btn text @click="cancelWithdraw()">Eliminar</v-btn>
      </template>
    </DialogBox>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Banner from "@/components/elements/Banner";
import DataTable from "@/components/elements/DataTable";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import DialogBox from "@/components/elements/DialogBox";
import * as currency from "currency.js";

export default {
  name: "Wallet",
  components: {
    Banner,
    DataTable,
    DialogBox
  },
  mixins: [FormatDateMixin, FormRulesMixin],
  data() {
    return {
      // Withdraws datatable
      tableHeaders: [
        { text: "Id", value: "id", sortable: false },
        { text: "Inversor", value: "investor", sortable: false },
        { text: "Cantidad", value: "amount", sortable: false },
        { text: "Moneda", value: "currency", sortable: false },
        { text: "Estado", value: "status", sortable: false },
        { text: "Fecha de solicitud", value: "created_at" },
        { text: "Fecha de envío", value: "completed_at" },
        { text: "", value: "actions", sortable: false }
      ],

      // Withdraw selected
      withdrawSelected: null,

      // Dialogs
      dialogConfirmSentWithdraw: false,
      dialogCancelWithdraw: false
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      withdraws: (state) => state.investors.withdraws
    }),
    currency() {
      return currency;
    }
  },
  async created() {
    await this.getWithdraws({ size: 10, sort: { created_at: "DESC" } });
  },
  methods: {
    ...mapActions({
      getWithdraws: "investors/getWithdraws"
    }),

    async handlePrevIcon(elasticData) {
      await this.getWithdraws({ ...elasticData });
    },

    async handleNextIcon(elasticData) {
      await this.getWithdraws({ ...elasticData });
    },

    async handleSearch(elasticData) {
      await this.getWithdraws({ ...elasticData });
    },

    // Redirect to investor sheet
    async pushToInvestorSheet(investorId) {
      const investor = await this.$store.dispatch("investors/getInvestor", { filter: { id: investorId } });
      this.$router.push(`/inversores/${investor.type.toLowerCase()}/${investorId}`);
    },

    // Change withdraw status to completed
    async completeWithdraw() {
      await this.$store.dispatch("investors/completeWithdraw", this.withdrawSelected.id);
      this.dialogConfirmSentWithdraw = false;
      await this.getWithdraws({ size: 10, sort: { created_at: "DESC" } });
    },

    // Delete withdraw
    async cancelWithdraw() {
      await this.$store.dispatch("investors/cancelWithdraw", this.withdrawSelected.id);
      this.dialogCancelWithdraw = false;
      await this.getWithdraws({ size: 10, sort: { created_at: "DESC" } });
    },

    // Close dialogs, clear and reset validation form fields
    closeDialog(dialogModel, formRef) {
      this.$refs[formRef].resetValidation();
      this[dialogModel] = false;
    }
  }
};
</script>
